
import axios from "axios"
//import { i18n } from 'vue-lang-router';

const state = () => ({
  products: [],

  reproConfig: {
    papier: [],
    sticker: [],
    toile_no_chassi: [],
    toile_chassi: []
  },

});

const actions = {
  addProduct({ commit }, product) {
    commit("addProduct", product);
  },
  removeProduct({ commit }, productId) {
    commit("removeProduct", productId);
  },
  clear({ commit }) {
    commit("clear");
  },
  initReproConfig({ commit }) {
    axios.post('/data/query-entities', { 
      entityType: 'reproduction',
      query: { 'type' : 'papier' }, 
      sort: "sizeNum" })
    .then((res)=>{
      commit("initReproduction", { key : "papier", data : res.data });
    })

    axios.post('/data/query-entities', { 
      entityType: 'reproduction',
      query: { 'type' : 'sticker' }, 
      sort: "sizeNum" })
    .then((res)=>{
      commit("initReproduction", { key : "sticker", data : res.data });
    })

    axios.post('/data/query-entities', { 
      entityType: 'reproduction',
      query: { 'type' : 'toile_chassi' }, 
      sort: "sizeNum" })
    .then((res)=>{
      commit("initReproduction", { key : "toile_chassi", data : res.data });
    })

    axios.post('/data/query-entities', { 
      entityType: 'reproduction',
      query: { 'type' : 'toile_no_chassi' }, 
      sort: "sizeNum" })
    .then((res)=>{
      commit("initReproduction", { key : "toile_no_chassi", data : res.data });
    })
  },
};

const mutations = {
  addProduct(state, product) {
    state.products.push(product)
  },
  removeProduct(state, productId) {
    let i = -1
    state.products.forEach((prod, x)=>{
      if(prod._id == productId) i = x
    })
    if(i >= 0) state.products.splice(i, 1)
  },
  clear(state) {
    state.products = []
  },
  initReproPapier(state, data) {
    state.reproConfig.papier = data.entities
  },
  initReproToile(state, data) {
    state.reproConfig.toile = data.entities
  },
  initReproduction(state, data) {
    state.reproConfig[data.key] = data.data.entities
  },
};

const getters = {
  
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
