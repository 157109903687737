<template>
  <div>
    <!-- TOP BAR -->
    <v-app-bar app elevation="1" color="" class="menu-top" hide-on-scroll>
        <v-app-bar-nav-icon 
        @click.stop="$store.dispatch('app/setDrawerOpen', !$store.state.app.drawerOpen)" />

        <router-link to="/" class="font-flower main-title-menu mr-2">
          {{ $store.state.app.siteName }}
        </router-link>


        <template v-if="showMenuComplete">
          <template v-for="(item) in itemsMenu">
            <v-btn :key="item.name" 
                    v-if="item.side == 'left' && item.show" 
                    :to="item.to"
                    text>
                    <v-icon small>{{ item.icon }}</v-icon>
                    {{ item.label }}
            </v-btn>
          </template>
        </template>

        <v-spacer/> 

        <div v-if="showMenuComplete" class="d-none d-md-flex">
            <template v-for="(item) in itemsMenu">
              <v-btn  :key="item.label" v-if="item.side == 'right' && item.show" rounded
                      :to="item.to" text class="mr-1">
                      <!-- <v-icon small>{{ item.icon }}</v-icon> -->
                      {{ item.label }}
              </v-btn>
          </template>
        </div>
        
        <v-spacer/> 

        <v-btn v-if="$store.state.basket.products.length > 0"  to="/panier"
              x-small class="mr-3" outlined fab>
          <v-badge color="green" :content="$store.state.basket.products.length">
            <v-icon small>mdi-basket</v-icon>
          </v-badge>
        </v-btn>


        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" elevation="0" icon class="mx-1 font-weight-bold">
              <img :src="currentLanguage.flagUrl" height="20px" /> 
            </v-btn>
          </template>
          <v-list dense>

            <language-switcher v-slot="{ links }">
              <v-list-item :to="link.url" v-for="link in links" @click="changeLocale(link.langIndex)" :key="link.langIndex">
                <img :src="require(`@/assets/flag/${link.langIndex}.png`)" 
                      alt="" class="mr-3" height="20px"/>
                <span>{{ link.langName }}</span>
              </v-list-item>
            </language-switcher>

          </v-list>
        </v-menu> -->

        <v-btn icon @click="newThemeDark()" class="mr-1">
          <v-icon>mdi-brightness-6</v-icon>
        </v-btn>

        <localized-link to="/admin" v-if="$store.state.auth.isLogin && $store.getters['auth/isAdmin']">
        <v-btn 
               icon style="margin-right:10px"
               color="secondary lighten-1">
          <v-icon>mdi-account-cowboy-hat</v-icon>
        </v-btn>
        </localized-link>

        <v-btn icon @click="logout" v-if="$store.state.auth.isLogin">
            <v-icon>mdi-logout</v-icon>
        </v-btn>
    </v-app-bar>
  </div>
</template>

<script>

import router from '../../router/router'
const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'top-bar',
  components: {
  },
  props: [ 'showMenuComplete' ],
  data: () => ({
    itemsMenu: [
      { side: 'right',  label: 'Galerie',              show:true,  to: '/gallery',      icon: 'mdi-stack-overflow' },
      { side: 'right',  label: 'Albums',               show:true, to: '/album',         icon: 'mdi-server-minus' },
      { side: 'right',  label: 'Boutique',             show:true,  to: '/shop',         icon: 'mdi-responsive' },
      { side: 'right',  label: 'Expos',                show:true,  to: '/expos',        icon: 'mdi-responsive' },
      { side: 'right',  label: 'Contact',              show:true,  to: '/contact',      icon: 'mdi-server-minus' },
    ],
    languages : [
      { label: "Fr", value: "fr" },
      { label: "En", value: "en" },
      { label: "Es", value: "es" },
    ],
    local: 'fr',
    nbNotifBuyer: 1
  }),
  
  mounted: function(){
    let lang = localStorage.getItem("lang")
    if(lang==null) lang = 'fr'
    this.$vuetify.lang.current = lang
  },
  
  methods: {
    logout(){
      this.$store.dispatch('auth/logout')
    },
    onClickLogo(){ router.push('/') },
    changeLocale (lang) {
      if(lang!=null) this.local = lang
      this.$vuetify.lang.current = this.local
      localStorage.setItem("lang", this.local)

      this.$root.$emit('langChanged')
      
    },
    newThemeDark(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem("themeDark", this.$vuetify.theme.dark)
    }
  },
  computed:{
    enableRegisterUser() { return config.enableRegisterUser },
    currentLanguage() {
      return {
        code: this.$i18n.locale,
        flagUrl: require(`../../assets/flag/${this.$i18n.locale}.png`)
      }
    },
  },
  watch: {

      '$store.state.app.data.instance': { immediate: true, 
          async handler () {
              console.log("$store.state.app.data.instance CHANGED", this.$store.state.app.data.instance)
              if(this.$store.state.app.data.instance.length > 0
              && this.$store.state.app.data.instance[0].useCommandeInMenu != null){
                this.itemsMenu.forEach((item, i)=>{
                  if(item.to == "/commande") 
                    this.itemsMenu[i].show = this.$store.state.app.data.instance[0].useCommandeInMenu
                })
              }
          }
      },
    }, 
  
  
};
</script>
