<template>
  <div>
    <!-- MAIN MENU -->
    <v-navigation-drawer v-model="$store.state.app.drawerOpen" app temporary color="">
      <v-list>

        <!-- <center><img alt="logo" class="mb-2" src="../../assets/logo-circle.png" height="150"></center> -->
        <router-link to="/" class="invisible-link">
          <!-- <img alt="logo" class="mb-2" src="../../assets/logo.png" width="50%" style="margin: 10px 25%;"> -->
          <span class="font-flower ml-5" style="font-size:25px;">{{ $store.state.app.siteName }}</span>
        </router-link>
        
        <v-col class="pa-0 my-4"></v-col>

        <template>
          <template v-for="item in itemsDrawer" >
            <template v-if="item.show">
            <v-list-item v-if="item.auth == false || $store.state.auth.isLogin" :key="item.to" link :to="item.to">

              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            </template>
          </template>
        </template>

        <v-list-group v-if="$store.getters['auth/isAdmin']"
          :value="false" color="secondary"
          prepend-icon="mdi-account-cowboy-hat"
        >
          <template v-slot:activator>
            <v-list-item-title>Admin Data</v-list-item-title>
          </template>

          <template v-for="item in $store.state.admin.entityTypesAvailables" >
            <v-list-item :key="item" link :to="'/admin/'+item">

              <v-list-item-action>
                <v-icon>mdi-database</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item|capitalize }}</v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
          </template>
        </v-list-group>

        <!-- <v-list-item>

          <v-list-item-action>
            <v-icon>mdi-brightness-6</v-icon>
          </v-list-item-action>

          <v-list-item-content style="z-index:2">
            <v-switch @change="newThemeDark()" v-model="$vuetify.theme.dark"></v-switch>
          </v-list-item-content>
          
        </v-list-item> -->

      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
  //import router from '../../router/router'

  export default {
    name: 'main-menu',
    props: [ 'showMenuComplete' ],
    data: () => ({
      drawer: null,
      itemsDrawer: [
        { name: 'Accueil',     to: '/',           show: true, auth: false, icon: 'mdi-home' },
        { name: 'Galerie',     to: '/gallery',    show: true, auth: false, icon: 'mdi-image-filter-vintage' },
        { name: 'Albums',      to: '/album',      show: true, auth: false, icon: 'mdi-image-album' },
        { name: 'Boutique',    to: '/shop',       show: true, auth: false, icon: 'mdi-basket' },
        { name: 'Expos',       to: '/expos',      show: true, auth: false, icon: 'mdi-artboard' },
        { name: 'Contact',     to: '/contact',    show: true, auth: false, icon: 'mdi-email' },
        // { name: 'Admin',       to: '/login',      show: true, auth: false, icon: 'mdi-account-cowboy-hat' },
      ]
    }),
    
    mounted: function(){
      //initialise le theme sur "sombre" ou "lumineux" (dark : true / false)
      setTimeout(()=>{
        let themeDark = localStorage.getItem("themeDark")
        if(themeDark != null) this.$vuetify.theme.dark = (themeDark=="true")
        else this.$vuetify.theme.dark = true
        
        localStorage.setItem("themeDark", this.$vuetify.theme.dark)
      }, 100)
      
    },
    
    methods: {
      logout(){
        this.$store.dispatch('auth/logout')
      },
      // newThemeDark(){
      //   localStorage.setItem("themeDark", this.$vuetify.theme.dark)
      // }
    },
    computed:{
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    watch: {

      '$store.state.app.data.instance': { immediate: true, 
          async handler () {
              console.log("$store.state.app.data.instance CHANGED", this.$store.state.app.data.instance)
              if(this.$store.state.app.data.instance.length > 0
              && this.$store.state.app.data.instance[0].useCommandeInMenu != null){
                this.itemsDrawer.forEach((item, i)=>{
                  if(item.to == "/commande") 
                    this.itemsDrawer[i].show = this.$store.state.app.data.instance[0].useCommandeInMenu
                })
              }
          }
      },
    }, 
    
    
  };
</script>
